html {
    user-select: none;
    cursor: default;
}

html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    background-color: #FAFAFA;
}

a {
    text-decoration: none;
}