@import "../../utils//mixins.scss";

.app-item {
    display: flex;
    flex-direction: column;
    margin: 30px 30px 0 0;
    padding: 20px;
    align-items: center;
    border-radius: 2px;
    color: #000000;
    width: 250px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    &:hover {
        transform: scale(1.05);
    }

    &--disabled {
        pointer-events: none;
    }

    &__logo {
        width: 180px;
        height: 180px;
        margin: 15px;
        border-radius: 30px;
    }

    &__title {
        font-size: 30px;
        font-weight: 500;
        white-space: normal;
        text-align: center;
        line-height: 98%;
    }

    &__subtitle {
        margin-top: 5px;
        font-size: 15px;
        font-weight: 300;
        white-space: normal;
        text-align: center;
        line-height: 98%;
        padding-bottom: 8px;
    }
}