.app-detail {
    display: flex;
    padding: 50px;
    flex-direction: column;
    flex-direction: row;
    margin-top: 30px;

    a {
        text-decoration: underline;
        color: #222222;
    }

    &__logo {
        height: 300px;
        width: 300px;
        border-radius: 50px;
    }

    &__info {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
        margin-top: 10px;
        font-size: 50px;
        font-weight: 700;
    }

    &__subtitle {
        font-size: 30px;
    }

    &__description {
        margin-top: 20px;
        font-size: 20px;

        p {
            margin: 10px 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__status {
        font-size: 20px;
        margin: 20px 0;
    }

    &__links {
        margin: 20px 0;
    }

    &__link-image {
        $scale: 0.35;
        width: 646px * $scale;
        height: 250px * $scale;
        position: relative;
        left: -15px;
    }

    &__landing-page-link-image {
        height: 60px;
        margin: 12px;
    }

    &__privacy {
        font-size: 15px;
        color: #555555;

        &:hover {
            color: black;
        }
    }
}