@import "../../utils/mixins.scss";

.footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px;
    font-weight: 300;
}

.footer__link {
    text-decoration: none;
    color: black;
    font-size: 20px;
}

.footer__cookies {
    margin-top: 5px;
    font-size: 14px;
}