@import "../../utils/mixins.scss";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    &__logo {
        width: 80px;
        height: 80px;
        margin-right: 30px;
        flex-shrink: 0;
    }

    &__left-content {
        display: flex;
        align-items: center;
    }

    &__title {
        color: #222222;
        font-size: 30px;
    }

    &__right-content {
        display: flex;
        margin-left: 40px;
    }

    &__menu-item {
        font-size: 20px;
        color: #222222;
        cursor: pointer;
        margin: 20px;
        text-decoration: none;
    }
}